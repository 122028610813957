<template>
  <div>
    <div class="userEndpoint">
      <div class="lock__right">
        <div class="canvas__align">
          <ul class="canvas__ul">
            <li  class="canvas__li">
              <span class="canvas__red"></span>
              <span :class="mode ? 'text' : 'textnight'">Не хватает сотрудников</span>
            </li>
            <li class="canvas__li">
              <span class="canvas__blue"></span>
              <span :class="mode ? 'text' : 'textnight'">В активном поиске</span>
            </li>
            <li class="canvas__li">
              <span class="canvas__green"></span>
              <span :class="mode ? 'text' : 'textnight'">Заполнено</span>
            </li>
          </ul>
        </div>
        <button v-if="true" @click="lockCanvas" class="lock__button">
          <i v-if="!lockChart" class="fa-solid fa-lock"></i>
          <i v-else class="fa-solid fa-lock-open"></i>
        </button>
      </div>
        <div v-loading="loadContent" class="canvas__layout">
          <!-- <butterfly-vue
            ref="bv"
            className='tree'
            :canvasConf="canvasConf"
            :canvasData="mockData"
            :baseCanvas="baseCanvas"
            :key="componentKey"
            @onLoaded="startLoaded"
            @onCreateEdge="changeEdges"
            @drawerInfo="drawerInfo"
          /> -->
        </div>  
    </div>
    <el-drawer
      :visible.sync="openDrawer"
      ref="staff-list"
      :with-header="false"
      @opened="drawerOpened('staff-list')"
      class="color-body"
    >
      <staff-list 
      @closeOpenDrawer="closeOpenDrawer"
      :child="child" 
      ref="staff-list"></staff-list>
    </el-drawer>
  
  </div>
</template>

<script>
import {ButterflyVue} from './butterfly-vue/index';
import { TreeCanvas, Node } from "butterfly-dag";
import mockData from './helper/mockData';
import helperGenerater from './helper/helperGenerater.js'
import staffList from "./components/staff-list.vue";
import { mapGetters, mapActions } from 'vuex';


export default {
  name: 'TreeCanvas',
  components: {
    ButterflyVue,
    staffList
  },
  data() {

    return {
      baseCanvas: TreeCanvas,
      loadContent: false,
      mockData,
      componentKey: 0,
      openDrawer: false,
      canvasConf: {
        disLinkable: false,
        linkable: false,
        draggable: false,
        zoomable: true, 
        moveable: true,
        autoResizeRootSize: true, 
        theme: {
          zoomGap: 0.001,
          edge: {
            shapeType: 'Manhattan',
            arrow: false,
          },
          endpoint: {
            position: [],       
            linkableHighlight: true,
            limitNum: 10,      
            expandArea: {   
              left: 15,
              right: 15,
              top: 15,
              botton: 15
            }
          },
          autoFixCanvas: { 
            enable: false,
            autoMovePadding: [20, 20, 20, 20]
          },
        },
        global: {
          isScopeStrict: false, 
        },
        layout: {
          type: 'compactBox',
          isFlatNode: true,
          options: {
            direction: 'TB',
            left: 300,
            link: {
                distance: 10000,
                strength: 3
            },
            getHeight(d) {
              return 120;
            },
            getWidth(d) {
              return 20;
            },
            getHGap(d) {
              return 180;
            },
            getVGap(d) {
              return 100;
            },
          },
        },
      },
      canvasRef: null,
      child: null,
      lockChart: false,
      edit: false
    }
  },
  async created() {
    //load
    this.loadContent = true;
    await this.getStructure()
    await this.generateData()
    await this.finishLoaded()
  },
  computed: {
    ...mapGetters({
      list: "structure/list",
      mode: "MODE",
      changedList: "structure/mockdata"
    }),
  },
  methods: {
    ...mapActions({
      getStructure: "structure/structure",
      updateStructure: "structure/updateStructure",
      changeState: "structure/changeState"
    }),
    generateData() {
      this.loadContent = false;
      this.mockData = (new helperGenerater(this.list)).getData()
    },
    
    lockCanvas() {
      this.loadContent = true
      this.lockChart = !this.lockChart
      if (this.lockChart) {
        this.canvasConf.draggable = true
        this.canvasConf.disLinkable = true
        this.canvasConf.linkable = true
      } else {
        this.canvasConf.draggable = false
        this.canvasConf.disLinkable = false
        this.canvasConf.linkable = false
      }
      this.componentKey += 1;
      setTimeout(() => this.finishLoaded(), this.loadContent = false, 1500);
      this.changeEdges()
    },
    startLoaded(VueCom) {
      this.butterflyVue = VueCom;
      this.canvansRef = VueCom.canvas;
      window.butterflyVue = VueCom;
    },
    finishLoaded() {
      this.canvansRef.setMinimap(true, {
        width: this.getScale().width,
        height: this.getScale().height,
        left: 20, 
        top: 0,
        nodeColor: "rgb(234,217,162)",
        activeNodeColor: "rgb(234,162,176)",
      });
      this.canvansRef.focusCenterWithAnimate()
      this.butterflyVue.canvas.setZoomable(true, true)
    },
    changeEdges (e) {
      const info = {
        id: e.id,
        parentId: e.otherInfo.sourceNode.options.open,
        parentType: e.otherInfo.sourceNode.options.key,
        childId: e.otherInfo.targetNode.options.open,
        childType: e.otherInfo.targetNode.options.key
      }
      this.updateStructure([info])
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    drawerInfo (e) {
      this.child = e
      this.openDrawer = true;
    },
    getScale () {
      let scaleX = this.butterflyVue.canvas.root.scrollWidth / 42
      let scaleY = this.butterflyVue.canvas.root.scrollHeight / 42
      let info = {
        width: scaleX,
        height: scaleY
      }
      return info
    },
    closeOpenDrawer(val) {
      this.openDrawer = val;
    },
  },
}
</script>
<style scoped>

</style>
